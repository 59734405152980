import React, { useState, useRef, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DevicesIcon from '@mui/icons-material/Devices';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { 
	API_END_POINT,
	API_APP_NAME } from '../App';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const styleModal = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  minWidth: 400,
	  bgcolor: 'background.paper',
	  border: '2px solid #000',
	  boxShadow: 24,
	  p: 4,
	};


 const API_METHOD_DELETE_CONF = "delConf";
 const API_METHOD_GET_CONFS = "getConfs";

 const _doDownload = (s,d) => {
	let filename = `${ d.uid }-${ s }.json`;
	let contentType = "application/json;charset=utf-8;";
	
	 if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify( d ))) ], { type: contentType });
		navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		var a = document.createElement('a');
		a.download = filename;
		a.href = 'data:'+contentType+','+encodeURIComponent(
		JSON.stringify( d ));
      		a.target = '_blank';
      		document.body.appendChild(a);
      		a.click();
      		document.body.removeChild(a);
    	}
 }



 const ConfigItemList = ( props ) => {
	const [ confToDelete, setConfToDelete] = React.useState("");

	const data = props.configItems[ props.serial ];

	const _deleteConf = ( c ) => {
		const auth = `Bearer ${props.jwt}`
		fetch( API_END_POINT , {
			method: "post",
			mode:'cors',
			headers: {	
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authentication': auth
			},
			//mak sure to serialize your JSON body
			body: JSON.stringify({
				method: API_APP_NAME,
				params: [ API_METHOD_DELETE_CONF, props.serial, c ],
				jsonrpc:"2.0",
				id:Math.floor(Math.random()*1000),
			})
		}).then( (response) => {
			return response.json()
		}).then( (response) => {
			if ( "error" in response ) {
				props.errorHandler( response["error"]["message"] );
			} else {
				console.log( response );
				try {
				if ( response["result"] ){
					props.patchData( props.serial, c );
				} else {
					props.eventHandler("failed");
				}

				} catch ( e ) {
					props.errorHandler( e );
				}
			}
		}).catch( e => {
			console.error(e);
		});
	}

	const _download = ( c ) => {



	const auth = `Bearer ${props.jwt}`
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: [ API_METHOD_GET_CONFS ,props.serial,  props.configFile  ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
			props.errorHandler( response["error"]["message"] );
		} else {
			console.log("HERE");
			console.log( JSON.stringify( response ) );
			try {
				
				const d = response["result"]["data"] ?? {};
				let filename = `${ props.serial }-${ props.str("CONFIG") }-${ c }.json`;
				let contentType = "application/json;charset=utf-8;";
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify( d ))) ], { type: contentType });
					navigator.msSaveOrOpenBlob(blob, filename);
				} else {
				var a = document.createElement('a');
					      		a.download = filename;
					      		a.href = 'data:'+contentType+','+encodeURIComponent(
								JSON.stringify( d ));
      							a.target = '_blank';
      							document.body.appendChild(a);
      							a.click();
      							document.body.removeChild(a);
    						}

						console.log("Cloud Download");	




			} catch ( e ) {
				props.errorHandler( e );
			}
		}
	}).catch( e => {
		console.error(e);
	}).catch( e => {
		console.error(e);
	});

	}

	const ConfirmationDialog = ( props  ) => {
		return (
			<Dialog
				onClose={ props.onClose }
				open={ props.open }>
			<DialogContent>
			<p>{ props.str("DELETE_CONFIRM") } </p>
			<i>{ props.str("CONFIG") + " "+props.configFile }</i>
			</DialogContent>
			<DialogActions>
				<Button 
					variant="contained"
					color="error"
					onClick={ e => {
						_deleteConf( props.configFile );
						props.onClose();
					}}
					>Delete</Button>
				<Button variant="contained"
			onClick={ e => props.onClose() }>Cancel</Button>
			</DialogActions>
			</Dialog>
		);
	}

	return <List>
		 <ConfirmationDialog
			configFile={ confToDelete }
	 		open={ confToDelete !== "" }
	 		onClose={ e => setConfToDelete("")}
		 	str={props.str}
		 />
	{ data.map( (timeKey,i) => (
		<ListItemButton key={timeKey+"-"+i}

		>
          		<ListItemIcon>
				<SettingsApplicationsIcon />
			</ListItemIcon>
			<ListItemText 

          		onClick={(e) => props.setConfigBatch( props.serial, timeKey ) }
		primary={timeKey} />
          		<ListItemIcon onClick={ e => {
				_download( timeKey  );
			}}>
				<CloudDownloadIcon />
			</ListItemIcon>
          		<ListItemIcon onClick={ e => {
				setConfToDelete( timeKey );
			}}>
				<DeleteIcon />
			</ListItemIcon>
        	</ListItemButton> )) }
	</List>
  }


const ConfigBatch = ( props ) => {

  const [aConfig, setConfig] = React.useState({});

  useEffect(() => {
	if ( Object.keys(aConfig).length == 0 ) _getConfigs();
  },[]);

  const _getConfigs = () => {

	  
	const auth = `Bearer ${props.jwt}`
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME, //"getConfs",
			params: [ API_METHOD_GET_CONFS , ... props.configBatch ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
			props.errorHandler( response["error"]["message"] );
		} else {
			console.log( response );
			try {
				
				const d = response["result"]["data"] ?? {};
				console.log( d );

				setConfig( d );
			} catch ( e ) {
				props.errorHandler( e );
			}
		}
	}).catch( e => {
		console.error(e);
	}).catch( e => {
		console.error(e);
	});
  }

  return (
	  <Grid container direction={"column"} spacing={2} >	
		<Grid item >
			<Grid container alignItems="center" >
				<Grid item >
					<IconButton aria-label="back" onClick={e => props.setConfigBatch("") }>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
				<Grid item xs >
					<Box textAlign="center">
						{ props.str("CONFIG") }
					</Box>		
				</Grid>
				<Grid item xs >
					<Box textAlign="right">
						<CloudDownloadIcon onClick={ (e) => _doDownload( props.str("CONFIG")+"-"+props.configBatch[1] , aConfig ) } />
					</Box>		
				</Grid>
			
	  		</Grid>
		</Grid>
			<Grid item >
	  	
		 <TableContainer component={Paper}>
      <Table aria-label="setting table">
        <TableHead>
          <TableRow>
            <TableCell>{ props.str("TABLE_SETTING") }</TableCell>
            <TableCell>{ props.str("TABLE_VALUE") }</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>	
		{ Object.keys(aConfig).map( (v,i) => (
			<TableRow key={`setting-${i}`}
              			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              			<TableCell component="th" scope="row">
					<Typography
						variant="subtitle"
						fontWeight="bold"
					>{v}</Typography>
				</TableCell>
              			<TableCell align="left">{`${aConfig[v]}`}</TableCell>
            		</TableRow>)) }
        </TableBody>
      </Table>
    </TableContainer>

		</Grid>
	</Grid>
  );
}

function Configs( props ) {
	const [serial, setSerial] = React.useState("");
	const [configBatch, setConfigBatch] = React.useState([]);
	const [open, setOpen] = React.useState(true);
	const [configItems, setConfigItems] = React.useState({});
	const [configMfrItems, setConfigMfrItems] = React.useState([]);
	const [jwtDetails, setJwtDetails] = React.useState({});	 
  	const [dataFilter, setDataFilter] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [modalState, setModalState] = React.useState([]);
	const handleModalClose = () => {
		setModalState([]);
		_getConfigItems( jwtDetails["scope"] );
	}

	useEffect(() => {
		const a = props.jwt.split(".");
		const r = JSON.parse( atob( a[1] ) );
		setJwtDetails( r );
		if ( Object.keys(configItems).length == 0 ) _getConfigItems( r["scope"] );
        },[]);

	const _getConfigItems = ( aScope ) => {
		console.log( aScope );
		let aMethod;
		let mParams;
		if ( aScope.includes( "mfrView") ){
			//aMethod = "getMfrConfs";
			mParams = [ API_METHOD_GET_CONFS , dataFilter ];
		} else {
			//aMethod = "getConfs";
			mParams = [ API_METHOD_GET_CONFS ];
		}



	const auth = `Bearer ${props.jwt}`
	
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: mParams,
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
			alert( JSON.stringify( response, null, 2 ) );
			props.errorHandler( response["error"]["message"] );
		} else {
			try {
				if ( aScope.includes( "mfrView") ) setConfigMfrItems( response["result"] );
				else setConfigItems( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}

		}
	}).catch( e => {
		console.error(e);
	});

	}

	const _patchData = ( serial , key ) => {
	  try {
		console.log(`_patchData(${serial},${key})`);
		let data = configItems[ serial ];
		console.log( configItems );
		const index = data.indexOf( key );
		data.splice( index , 1 );
		setConfigItems(prev => ({...prev,[serial]:data}));
		  console.log( setConfigItems )
	} catch(e){
		console.error(`_patchData ${ e}`);
	}

	}


const FilterForm = ( props ) => {

	const [use,setUse] = React.useState(1);
	const [serial,setSerial] = React.useState("");

	const setValue = ( key , value ) => {
		if ( "serial" == key ) {
			props.setFilter( prev => ({ ...prev, "type":value }) );
		} else if ( "openFrom" == key ){
			props.setFilter( prev => ({ ...prev, "openFrom" : dayjs(value).format("YYYY-MM-DD HH:mm:ss") }) );
		} else if ( "openTo" == key ) {
			props.setFilter( prev => ({ ...prev, "openTo" : dayjs(value).format("YYYY-MM-DD HH:mm:ss") }) );
			
		}
	}

	return( 
		<>
		<Grid container direction={"column"} spacing={1}>
			<Grid item>
				<LocalizationProvider sx={{ width: 0.5 }} dateAdapter={AdapterDayjs}>
					<DatePicker sx={{ width: 0.5 }} label={ props.str("FILTER_OPEN_FROM") }
						onChange={ e=> setValue("openFrom", dayjs(e) ) } />
				</LocalizationProvider>
				<LocalizationProvider sx={{ width: 0.5 }} dateAdapter={AdapterDayjs}>
					<DatePicker sx={{ width: 0.5 }} label={ props.str("FILTER_OPEN_TO") }
						onChange={ e=> setValue("openTo", dayjs(e) ) } />
				</LocalizationProvider>
			</Grid>	
		</Grid>	
	</>
	);
}





	const ConfigUsr = () => {
	return <>
		{ configBatch.length === 0 ?
		<>
			{ Object.keys( configItems ).length == 0 && <Box textAlign="center"> { props.str("NO_UPLOADS") } </Box> }
			<Grid container direction={"column"} spacing={2} >
				<Grid item >
	  				<List>
			{ Object.keys( configItems ).map( (serial,i) => 
				<div key={"select-"+i}>
					<ListItemButton key={`ser-${i}`} /*onClick={(e) => setSerial( serial ) }*/ >
          					<ListItemIcon><CropSquareIcon /></ListItemIcon>
						<ListItemText primary={`${props.str("DEVICE_SERIAL")} #${serial}`} />
					</ListItemButton>
					<Box sx={{ml:6}}>
					<ConfigItemList
						jwt={ props.jwt }
						errorHandler={ props.errorHandler }
						eventHandler={ props.eventHandler }
						str={props.str}	
						key={`confList-${i}`}
						serial={serial}
						configItems={ configItems }
						patchData={ _patchData }
						setConfigBatch={ (s,b) => {
							console.log(`${s} ${b}`);
							setConfigBatch([s,b]);
						} } /> 
			</Box>
		
			</div>
		) }
		</List>
		</Grid>
	</Grid></>
   : 
	<ConfigBatch
			jwt={props.jwt}
			str={props.str}
			configBatch={configBatch}
			errorHandler={props.errorHandler}
			eventHandler={props.eventHandler}
			setConfigBatch={setConfigBatch} />
} </>;

};

	const SerialConfView = (props) => {
		const [serialConfigs,setSerialConfigs] = React.useState([]);
 useEffect(() => {
	if ( Object.keys(serialConfigs).length == 0 ) _getSerialConfigs( props.serial );
	
  },[]);






const _getSerialConfigs = ( aSerial ) => {

		const mParams = [ API_METHOD_GET_CONFS, { "serial": aSerial["name"] } ];

	const auth = `Bearer ${props.jwt}`
	
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: mParams,
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
			props.errorHandler( response["error"]["message"] );
		} else {
			try {
				
				console.log( response );
				setSerialConfigs( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}

		}
	}).catch( e => {
		console.error(e);
	});

	}












		return(
			<>
			<Grid container direction={"column"} spacing={2} >	
			<Grid item >
				<Grid container alignItems="center" >
				<Grid item >
					<IconButton aria-label="back" onClick={e => props.callback("") }>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
				<Grid item xs >
					<Box textAlign="center">
						<h2>{ props.str("CONFIG_UPLOADS") }</h2>
					</Box>		
				</Grid>
			</Grid>
		</Grid>
		<Grid item >
			{
				serialConfigs.map( ( c,i) => {
					let d = null;
					 try{
						d = JSON.parse( c["value"] );
					 }catch(e){

					 }
					 return <Accordion key={"conf-"+i}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
						{ c["name"] + " @ " + c["open_date"] }
        </AccordionSummary>
        <AccordionDetails>
						
 <TableContainer component={Paper}>
      <Table aria-label="setting table">
        <TableHead>
          <TableRow>
            <TableCell>{ props.str("TABLE_SETTING") }</TableCell>
            <TableCell>{ props.str("TABLE_VALUE") }</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>	
						{ d && typeof d==='object' && Object.keys(d).map( (v,i) => {
	return <TableRow key={`setting-${i}`}
              			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              			<TableCell component="th" scope="row">
					<Typography
						variant="subtitle"
						fontWeight="bold"
					>{v}</Typography>
				</TableCell>
              			<TableCell align="left">{`${d[v]}`}</TableCell>
            		</TableRow>

						

						})

						}

  </TableBody>
      </Table>
    </TableContainer>
        </AccordionDetails>
      </Accordion>


				})

			}
		</Grid>
		</Grid>

			</>
		);
	}

	const ConfigMfr = () => {

		return <>
	{ Object.keys(dataFilter).length > 0 ? 
		<IconButton
			onClick={ e => {
				setDataFilter({});
				_getConfigItems("mfrView");
			} }
			aria-label="filter-clear" size="large">
			<FilterListOffIcon fontSize="inherit" />
		</IconButton> :
		<IconButton
			onClick={ e => setModalState( [ "filter" ] ) }
			aria-label="filter" size="large">
			<FilterListIcon fontSize="inherit" />
		</IconButton> }
		<Modal
			open={modalState.length !== 0}
			onClose={handleModalClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description" >
			<Box sx={styleModal}>
				{ modalState[0] ==="filter" ? 
					<FilterForm str={props.str} setFilter={setDataFilter}/> :
					<SerialConfView
						str={props.str}
						errorHandler={props.errorHandler}
						jwt={props.jwt}
						serial={ modalState[1] } /> }
			</Box>
		</Modal>
		{ loading ? <CircularProgress /> : <><TableContainer component={Paper}>
		      <Table sx={{ minWidth: 650 }} aria-label="simple table">
			<TableHead>
			  <TableRow>
			    <TableCell align="right"> Serial </TableCell>
			    <TableCell align="right"> Type </TableCell>
			    <TableCell align="right"> Open Date </TableCell>
			  </TableRow>
			</TableHead>
			<TableBody>
			  {configMfrItems.map((row,i) => (
			    <TableRow
			      key={ `row-${i}`}
			      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				 onClick={ e => { setSerial(row) } }
			    >
			      <TableCell component="th" scope="row">{row.name}</TableCell>
			      <TableCell align="right">{row.type}</TableCell>
			      <TableCell align="right">{row.open_date}</TableCell>
			    </TableRow>
			  ))}
			</TableBody>
		      </Table>
			<Box
				display="flex"
			  	spacing={0}
  				direction="row"
				justifyContent="center"	
				alignItems="center">
				<IconButton
					onClick={ e => {
						let fltr = dataFilter;
						fltr["offset"] = configItems.length;
						
						fetch( API_END_POINT , {
							method: "post",
							mode:'cors',
							headers: {	
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							//make sure to serialize your JSON body
							body: JSON.stringify({
								method:"getDevices",
								params: fltr,
								jsonrpc:"2.0",
								id:Math.floor(Math.random()*1000),
							})
						}).then( (response) => {
							return response.json()
						}).then( (response) => {
							if ( response.result.length > 0 ) {
								setConfigMfrItems( prev => ([...prev,response.result]));
							}
							setLoading( false );
						}).catch( e => {
							console.error(e);
							setLoading( false );
						});
					}} aria-label="more" size="large">
			<ExpandMoreIcon fontSize="inherit" />
		    </IconButton>
			</Box >
		</TableContainer>
		   </>
		}



			</>
	}



	if ( !("scope" in jwtDetails) ) return <></>
	if ( serial !== "") return <SerialConfView
						callback={ e => setSerial(e)  }
						str={props.str}
						errorHandler={props.errorHandler}
						jwt={props.jwt}
						serial={ serial } /> 
	return jwtDetails["scope"].includes("mfrView") ? <ConfigMfr />: <ConfigUsr />


}

export default Configs;

import React, { useState, useRef, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DevicesIcon from '@mui/icons-material/Devices';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BarChartIcon from '@mui/icons-material/BarChart';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API_END_POINT, API_APP_NAME} from '../App';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { InputAdornment } from '@mui/material';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import MergeIcon from '@mui/icons-material/Merge';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const RecordItemList = ( props ) => {
	  
	  const [ selectedArr, setSelectedArr ] = React.useState([]);
	  const [ anAction, setAnAction ] = React.useState("");

	  const handleAction = ( ) => {
	    console.log(`handleAction()`);
	    switch( anAction ) {
		case "none": setSelectedArr([]); break;
		case "merge": {
			_mergeFiles( selectedArr );
		} break;
		case "delete": {
			_deleteFiles( selectedArr );
		} break;
	    }
	  };
  	
	  const handleIconClick = (v) => {
	    const index = selectedArr.indexOf( v );
	    if ( index > - 1 ) {
	    	setSelectedArr( selectedArr.filter( item => item !== v ) );
	    } else setSelectedArr( prev => [...prev, v]);
	    //console.log(`handleDoubleClick: ${ v } in ${ selectedArr }`);
	  };

	 const _deleteFiles = ( batchKeys ) => {
		const auth = `Bearer ${props.jwt}`
		fetch( API_END_POINT , {
			method: "post",
			mode:'cors',
			headers: {	
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authentication': auth
			},
			//mak sure to serialize your JSON body
			body: JSON.stringify({
				method: API_APP_NAME,
				params: [ "delRecords", props.serial, batchKeys ],
				jsonrpc:"2.0",
				id:Math.floor(Math.random()*1000),
			})
		}).then( (response) => {
			return response.json()
		}).then( (response) => {
			if ( "error" in response ) {
				props.errorHandler( response["error"]["message"] );
			} else {
				console.log( response );
				try {
				if ( response["result"] ){
					props.patchData( props.serial, batchKeys ,"");
					setSelectedArr([]);
				} else {
					props.eventHandler("failed");
				}

				} catch ( e ) {
					props.errorHandler( e );
				}
			}
		}).catch( e => {
			console.error(e);
		});
	 }

	const _mergeFiles = ( batchKeys ) => {
		const auth = `Bearer ${props.jwt}`
		fetch( API_END_POINT , {
			method: "post",
			mode:'cors',
			headers: {	
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authentication': auth
			},
			//mak sure to serialize your JSON body
			body: JSON.stringify({
				method: API_APP_NAME,
				params: [ "merge", props.serial, batchKeys ],
				jsonrpc:"2.0",
				id:Math.floor(Math.random()*1000),
			})
		}).then( (response) => {
			return response.json()
		}).then( (response) => {
			if ( "error" in response ) {
				props.errorHandler( response["error"]["message"] );
			} else {
				console.log( response );
				try {
				if ( response["result"] !== "" ){
					props.patchData( props.serial, batchKeys ,response["result"]);
					setSelectedArr([]);
				} else {
					props.eventHandler("failed");
				}
				} catch ( e ) {
					props.errorHandler( e );
				}
			}
		}).catch( e => {
			console.error(e);
		});

	}


	const formActions = () => {
 return <Box difplay="flex" gap={2}>
<Button variant="contained"
		sx={{m:1}}	
		startIcon={<ClearIcon /> }
		onClick={ (e) => setSelectedArr([]) } >
			{ props.str("CLEAR")  }
</Button>
{ selectedArr.length > 1 && 
<Button variant="contained"
		sx={{m:1}}	
		startIcon={<MergeIcon /> }
		onClick={e => setAnAction("ACTION_MERGE") }>
			{ props.str("ACTION_MERGE")  }
</Button>}

<Button variant="contained" color="error"
		sx={{m:1}}	
		startIcon={<DeleteIcon /> }
		onClick={e => setAnAction("ACTION_DELETE") }>
			{ props.str("ACTION_DELETE")  }
</Button>
</Box>

	}


	 return (
		 <>
		 { props.showActions && selectedArr.length > 0 && formActions() }
    <Dialog onClose={ (e) => setAnAction("")  } open={ anAction !== "" }>
		 <DialogTitle> { props.str("CONFIRM") }  </DialogTitle>
		 <DialogContent sx={{m:2}}>
		 <DialogContentText>
		 { props.str("ARE_YOU_SURE")+" "+props.str( anAction ) }
		 <br/>
		 {  selectedArr.map( (v,i ) => <><span key={"action-"+i} >{"- "+ v  }</span><br/></> )} 
						

		 </DialogContentText>
		 </DialogContent>
		 <DialogActions>
<Button variant="contained" color="error"
		 onClick={(e) => { if (anAction === "ACTION_MERGE") _mergeFiles( selectedArr ); else if (anAction ==="ACTION_DELETE"  ) _deleteFiles( selectedArr ); setAnAction("") }}
		sx={{m:1}}	
>{ props.str("CONFIRM") } </Button><Button variant="contained" sx={{m:1}}
		 onClick={e => setAnAction("")} >{ props.str("CANCEL") } </Button>

		 
		 </DialogActions>

		 </Dialog>

		 <List>
		{ props.recordItems[ props.serial ].map( (timeKey,i) => (
			<ListItemButton	key={ props.serial+"-"+timeKey+"-"+i} >
				<ListItemIcon> 

				{ props.showActions ?
					( ( selectedArr.indexOf( timeKey ) > -1 ) ?
						<CheckCircleOutlineIcon 
							onClick={ (e) => handleIconClick( timeKey ) } /> :
						<RadioButtonUncheckedIcon
							onClick={ (e) => handleIconClick( timeKey ) } /> ) :
					<BarChartIcon /> }

        			</ListItemIcon>
				<ListItemText
          				onClick={(e) => props.setRecordBatch( props.serial  , timeKey ) }
					primary={timeKey} />
        		</ListItemButton> )) }
		</List>
		
		 </>
	 );
  }

const WeigherSelection = ( props ) => {

	if ( Object.keys( props.data ).length == 0 ) return (
		<Box textAlign="center">
		{ props.str("NO_UPLOADS") }
		</Box>		
	);

	
	return <Grid container direction={"column"} spacing={2} >	
				<Grid item >
	  	<List>
		{ Object.keys(props.data).map( (serial,i) => (
			<div key={"select-"+i}>
			<ListItemButton key={`serial-${i}`} onClick={(e) => props.setSerial( serial ) } >
          			<ListItemIcon><CropSquareIcon /></ListItemIcon>
				<ListItemText primary={`${props.str("DEVICE_SERIAL")} #${serial}`} />
			</ListItemButton>
			<Box sx={{ml:6}}>
			<RecordItemList
				jwt={ props.jwt }
				str={ props.str }
				key={`recordList-${i}`}
				showActions={ false }
				serial={serial}
				patchData={props.patchData}
				eventHandler={props.eventHandler}
				errorHandler={ props.errorHandler }
				recordItems={ props.data }
				setRecordBatch={ (s,b) => {
					console.log(`call setRecordBatch ${ s } -  ${ b } `);

					props.setRecordBatch(serial,b);


				} } /> 
			</Box>
			</div>
		)) }
		</List>
		</Grid>
	</Grid>
}

const RecordBatch = ( props ) => {
 
  const [records, setRecords] = React.useState([]);

 useEffect(
        () => {
		if ( records.length == 0 ) _getRecords();
        },[]);

	const _getRecords = () => {

	const auth = `Bearer ${props.jwt}`
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: [ "getRecords" , props.serial, props.recordBatch ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
			props.errorHandler( response["error"]["message"] );
		} else {
			try {
				setRecords( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}

		}
	}).catch( e => {
		console.error(e);
	});


	}

	return (  
	<Grid container direction={"column"} spacing={2} >	
		<Grid item >
			<Grid container alignItems="center" >
				<Grid item >
					<IconButton aria-label="back" onClick={e => props.setRecordBatch("") }>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
				<Grid item xs >
					<Box textAlign="center">
						{`#${props.serial}`} <b>{`${props.str("RECORD_MANAGEMENT")}` }</b> <i>{ `${props.recordBatch}` }</i>
					</Box>		
				</Grid>
				<Grid item >
					<IconButton aria-label="back" onClick={e => {
						
						let filename = `${ props.serial }-${ props.str("RECORD_MANAGEMENT") }-${ props.recordBatch }.json`;
					    	let contentType = "application/json;charset=utf-8;";
					    	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					      		var blob = new Blob(
								[decodeURIComponent(
									encodeURI(JSON.stringify( records["data"] )))
								], { type: contentType });
					      			navigator.msSaveOrOpenBlob(blob, filename);
					    	} else {
					      		var a = document.createElement('a');
					      		a.download = filename;
					      		a.href = 'data:'+contentType+','+encodeURIComponent(
								JSON.stringify(records["data"]));
      							a.target = '_blank';
      							document.body.appendChild(a);
      							a.click();
      							document.body.removeChild(a);
    						}

						console.log("Cloud Download");	
					} }>
						<CloudDownloadIcon />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
		
		<Grid item >
	  		<TableContainer component={Paper}>
      				<Table sx={{ minWidth: 650 }} aria-label="simple table">
        				<TableHead>
          					<TableRow>
            						<TableCell>{ props.str("RECORD_UUID") }</TableCell>
							<TableCell align="right">{ props.str("RECORD_GROSS") }</TableCell>
					    		<TableCell align="right">{ props.str("RECORD_NET") }</TableCell>
					    		<TableCell align="right">{ props.str("RECORD_TARE") }</TableCell>
					    		<TableCell align="right">{ props.str("RECORD_REFERENCE") }</TableCell>
					  	</TableRow>
					</TableHead>
					<TableBody>	
						{ "data" in records && records["data"].map( (r,i) => (
						<TableRow key={"record-"+i}
              						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              						<TableCell component="th" scope="row">{r["uid"]}</TableCell>
              						<TableCell align="right">{r["gross"]}</TableCell>
              						<TableCell align="right">{r["net"]}</TableCell>
              						<TableCell align="right">{r["tare"]}</TableCell>
              						<TableCell align="right">{r["reference"]}</TableCell>
            					</TableRow>)) }
        				</TableBody>
      				</Table>
    			</TableContainer>
		</Grid>
	</Grid>
	);
}

function Records( props ) {
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [serial, setSerial] = React.useState("");
  const [recordBatch, setRecordBatch] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [recordItems, setRecordItems] = React.useState({});
	
  useEffect(
        () => {
		if ( Object.keys(recordItems).length == 0 ) _getRecordItems();
        },[]);

  const _patchData =( serial, items, merged  ) => {
	console.log(`_patchData( ${serial} , ${items} ,${merged}`);
	  try {
		let data = recordItems[ serial ];

		const newData = data.filter( i => {
			return items.indexOf( i ) == -1;
		});

		if ( merged !== '' ) {
			newData.push( merged );
		}
		setRecordItems(prev => ({...prev,[serial]:newData}));

	} catch(e){
		console.error(`_patchData ${ e}`);
	}
};

  const _getRecordItems = () => {

	const auth = `Bearer ${props.jwt}`
	
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: [ "getRecords" ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		console.log( response );
		if ( "error" in response ) {

			props.errorHandler( response["error"]["message"] );
		} else {
			try {
				setRecordItems( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}

		}
	}).catch( e => {
		console.error(e);
	});

	}




	//TODO: put this in the update loop e.g in one return
	if ( !serial ) return <WeigherSelection
			jwt={props.jwt}
			str={props.str}
			data={ recordItems }
			errorHandler={props.errorHandler}
			eventHandler={props.eventHandler}
			patchData={ _patchData }
			setSerial={ setSerial }
			setRecordBatch={ (s,b) => {
				setSerial(s);
				setRecordBatch(b);
		}}/>

	if ( serial && recordBatch !== "" ) return <RecordBatch
			jwt={props.jwt}
			serial={serial}
			recordItems={ recordItems[ serial ] }
			recordBatch={ recordBatch }
			str={props.str}
			errorHandler={props.errorHandler}
			eventHandler={props.eventHandler}
			setRecordBatch={setRecordBatch}
		/>
	if ( serial ) return <Grid container direction={"column"} spacing={2} >	
		<Grid item >
			<Grid container alignItems="center" >
				<Grid item >
					<IconButton aria-label="back" onClick={e => setSerial("") }>
						<ArrowBackIcon />
					</IconButton>
				</Grid>
				<Grid item xs >
					<Box textAlign="center">
					<i>{`#${serial}`}</i> <b>{ props.str("RECORD_UPLOADS") }</b>
					</Box>		
				</Grid>
			</Grid>
		</Grid>
		<Grid item >
	  		<List>
				<RecordItemList
					jwt={ props.jwt }
					str={ props.str }
					serial={ serial }
					showActions={ true }
					eventHandler={props.eventHandler}
					errorHandler={props.errorHandler}
					recordBatch= { recordBatch }
					recordItems={ recordItems }
					patchData={ _patchData }
					setRecordBatch={ ( s , b ) => {
						setRecordBatch( b );

					} } /> 
			</List>
		</Grid>
	</Grid>
	
}

export default Records;

import React, { useState, useRef, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import DevicesIcon from '@mui/icons-material/Devices';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API_END_POINT, API_APP_NAME } from '../App';


function Summary( props ) {
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState({});
  
  useEffect(() => {
	if ( Object.keys(data).length == 0 ) _getData();
  },[]);

  const _getData = () => {
	
/*	setData( {
		"devices": 10,
		"record Uploads": 12,
		"configs":3,
	} );
*/
	const auth = `Bearer ${props.jwt}`
	
	fetch( API_END_POINT , {
		method: "post",
		mode:'cors',
		headers: {	
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authentication': auth
		},
		//mak sure to serialize your JSON body
		body: JSON.stringify({
			method: API_APP_NAME,
			params: [ "summary" ],
			jsonrpc:"2.0",
			id:Math.floor(Math.random()*1000),
		})
	}).then( (response) => {
		return response.json()
	}).then( (response) => {
		if ( "error" in response ) {
	

				props.errorHandler( response["error"]["message"] );

		} else {
			try {
				setData( response["result"] );
			} catch ( e ) {
				props.errorHandler( e );
			}
		}
	}).catch( e => {
		console.error(e);
	}).catch( e => {
		console.error(e);
	});
  }

	
	return(
	<Grid container direction={"column"} spacing={2} >	
		<Grid item >
			{ props.str("SUMMARY") }
		</Grid>
		<Grid item >
	  <TableContainer component={Paper}>
      <Table aria-label="summary table">
        <TableBody>	
		{ Object.keys(data).map( (v,i) => (
			<TableRow key={`setting-${i}`}
              			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              			<TableCell component="th" scope="row">
					<Typography
						variant="subtitle"
						fontWeight="bold"
					>{ props.str(v) }</Typography>
				</TableCell>
              			<TableCell align="left">{`${data[v]}`}</TableCell>
            		</TableRow>)) }
        </TableBody>
      </Table>
    </TableContainer>

</Grid>
	</Grid>
	);
}

export default Summary;
